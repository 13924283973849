import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import SearchSelect from "../../components/common/Search";
//课程类型
export default {
  name: "CourseType",
  data: function data() {
    return {
      // 筛选
      filter: [{
        placeholder: '文件类型状态',
        value: '',
        type: 'select',
        list: [{
          id: '1',
          name: '正常'
        }, {
          id: '-1',
          name: '禁用'
        }]
      }, {
        placeholder: '文件类型名称',
        value: '',
        type: 'text',
        list: []
      }],
      // 用户信息
      userInfo: this.Ls.getParseToken("nof_userInfo"),
      // List接口返回表格数据
      tableKey: 1,
      //表格的key
      tableData: [],
      total: 0,
      //页数总数
      currentPage: 1,
      // 当前页数
      pageSize: 20,
      //每页个数
      // drawer抽屉-数据
      drawer: false,
      //是否显示弹框
      direction: "rtl",
      drawerState: "",
      drawerData: {
        name: "",
        alias: "",
        //类型别名
        value: "",
        //类型值
        description: "",
        //描述
        rank: "",
        //排序
        state: 1 //1启用 -1禁用
      },

      // dialog弹框-删除弹框
      delId: "",
      centerDialogVisible: false // 删除弹框显示隐藏
    };
  },

  watch: {},
  components: {
    SearchSelect: SearchSelect
  },
  created: function created() {
    //获取数据
    this.initData();
  },
  methods: {
    //筛选功能
    search: function search(filterArr) {
      var _this = this;
      this.service.axiosUtils({
        requestName: "getResTypeList",
        data: {
          name: filterArr[1].value,
          state: filterArr[0].value,
          pageSize: this.pageSize,
          pageIndex: this.currentPage
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this.tableData = res.data.data;
            _this.total = res.data.total;
            _this.initType();
          } else {
            _this.$message.error(res.message);
          }
        }
      });
    },
    //重置
    reload: function reload() {
      this.filter[0].value = "";
      this.filter[1].value = "";
      this.initData();
    },
    // 添加函数
    addFun: function addFun() {
      this.drawerState = "add";
      this.drawer = true;
    },
    // 初始化数据
    initData: function initData() {
      var _this2 = this;
      this.service.axiosUtils({
        requestName: "structuretypeGetList",
        data: {
          userId: this.userInfo.userId,
          roleId: this.userInfo.roleId,
          pageSize: this.pageSize,
          pageIndex: this.currentPage
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this2.tableData = res.data.data;
            _this2.total = res.data.total;
          } else {
            _this2.$message.error(res.message);
          }
        }
      });
    },
    // page-页码修改pagesize
    handleSizeChange: function handleSizeChange(page) {
      this.pageSize = page;
      this.initData();
    },
    // page-页码修改当前页码
    handleCurrentChange: function handleCurrentChange(page) {
      this.currentPage = page;
      this.initData();
    },
    // table-修改正常/禁用状态
    editState: function editState(row) {
      var _this3 = this;
      var rowState = "";
      if (row.state === 1) {
        rowState = -1;
      } else {
        rowState = 1;
      }
      this.service.axiosUtils({
        requestName: "structuretypeChangeState",
        data: {
          userId: this.userInfo.userId,
          roleId: this.userInfo.roleId,
          id: row.id,
          state: rowState
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this3.$toast.success(res.message);
            if (row.state === 1) row.state = -1;else row.state = 1;
          } else {
            _this3.$toast.error(res.message);
          }
        }
      });
    },
    // table-中操作函数
    detail: function detail(type, val) {
      var _this4 = this;
      if (type === "edit") {
        this.drawerState = "edit";
        this.drawer = true;
        setTimeout(function () {
          _this4.drawerData = {
            name: val.name,
            alias: val.alias,
            value: val.value,
            description: val.description,
            rank: val.rank,
            state: val.state,
            id: val.id
          };
        }, 0);
      } else if (type === "del") {
        this.centerDialogVisible = true;
        this.delId = val.id;
      } else {}
    },
    // drawer抽屉-取消函数
    cancelClick: function cancelClick() {
      this.$nextTick(function () {
        this.$refs["form"].resetFields();
      });
      this.drawer = false;
    },
    // drawer抽屉-确定函数
    confirmClick: function confirmClick(formName) {
      var that = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var url = "";
          that.drawerData.userId = that.userInfo.userId;
          that.drawerData.roleId = that.userInfo.roleId;
          if (that.drawerState === "edit") {
            //修改
            url = "editStructureType";
          } else {
            //添加课程类型
            url = "addStructureType";
          }
          that.service.axiosUtils({
            requestName: url,
            data: that.drawerData,
            sucFn: function sucFn(res) {
              if (res.status === 1) {
                that.$toast.success(res.message);
                that.drawer = false;
                that.drawerData = {
                  name: "",
                  code: "",
                  rank: "",
                  state: ""
                };
                if (that.drawerState === "add") {
                  that.currentPage = 1;
                }
                that.$nextTick(function () {
                  that.$refs["form"].resetFields();
                });
                that.initData();
              } else {
                that.$toast.error(res.message);
              }
            }
          });
        }
      });
    },
    // dialog弹框-删除弹框确定函数
    confirmFun: function confirmFun() {
      var _this5 = this;
      //删除当前的文件类型
      this.service.axiosUtils({
        requestName: "deleteStructureType",
        data: {
          id: this.delId
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this5.$toast.success(res.message);
            _this5.delId = "";
            _this5.centerDialogVisible = false;
            _this5.initData();
          } else {
            _this5.$toast.error(res.message);
          }
        }
      });
    }
  }
};